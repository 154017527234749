.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 120px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  z-index: 100;
}

.spinner-square > .square {
  width: 17px;
  height: 70px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  -webkit-animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
          animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
}

.square-2 {
  -webkit-animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
          animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
}

.square-3 {
  -webkit-animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
          animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
}

@-webkit-keyframes square-anim {
  0% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
  20% {
    height: 80px;
  }
  40% {
    height: 120px;
    background-color: rgb(111, 200, 240);
  }
  80% {
    height: 80px;
  }
  100% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
}

@keyframes square-anim {
  0% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
  20% {
    height: 80px;
  }
  40% {
    height: 120px;
    background-color: rgb(111, 200, 240);
  }
  80% {
    height: 80px;
  }
  100% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
}