@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

.notification {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background: rgba(235, 14, 14, 0.7);
	color: #fff;
	font-size: 16px;
	font-weight: 700;
	min-width: 300px;
	position: fixed;
	right: 0px;
	/* left: 0px; */
	top: 60px;
	transform: translateX(-300px);
	transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: 'Raleway';
	box-shadow: 0px 0px 3px 1px rgb(0, 0, 0, 0.5);
}
.pop {
	/* display: flex; */
	transform: none;
}
.notification svg {
	fill: #fff;
	margin-right: 15px;
}
